import React from 'react';
import config from '../../config';
import css from './ListingPage.module.css';
import { FormattedMessage } from '../../util/reactIntl';
import { PropertyGroup } from '../../components';
import { useIntl } from 'react-intl';
const SectionCompositionMaybe = props => {
  const { publicData } = props;
  if (!publicData) return null;
  const { composition } = publicData ?? [];
  if (!composition) {
    return null;
  }
  const intl = useIntl();
  const compositionOptions = composition?.map(item =>
    config.custom.compositionOptions.find(compositionItem => compositionItem.key === item)
  );
  return composition?.length ? (
    <div className={css.sectionFeatures}>
      <h2 className={css.detailsTitle}>
        <FormattedMessage id="ListingPage.compositionTitle" />
      </h2>
      <PropertyGroup
        id="ListingPage.composition"
        options={compositionOptions?.map(k => ({
          ...k,
          label: intl.formatMessage({ id: k.label }),
        }))}
        selectedOptions={composition}
        threeColumns={true}
      />
    </div>
  ) : null;
};

export default SectionCompositionMaybe;
