import React from 'react';
import config from '../../config';
import css from './ListingPage.module.css';
import { FormattedMessage } from '../../util/reactIntl';
import { AiOutlineColumnWidth } from 'react-icons/ai';
import { SiZend } from 'react-icons/si';
const SectionSizeMaybe = props => {
  const { publicData } = props;
  if (!publicData) return null;
  const { fabrics_width, swatch_length, trims_length, trims_width } = publicData;

  const sectionfabricsWidth = fabrics_width ? (
    <div className={css.sectionFeatures}>
      <h2 className={css.detailsTitle}>
        <FormattedMessage id="ListingPage.fabricsWidth" />
      </h2>
      <div className={css.detailsContent}>{fabrics_width}cm</div>
    </div>
  ) : null;
  const sectionSwatchLength = swatch_length ? (
    <div className={css.sectionFeatures}>
      <h2 className={css.detailsTitle}>
        <FormattedMessage id="ListingPage.swatchLength" />
      </h2>
      <div className={css.detailsContent}>{swatch_length}cm</div>
    </div>
  ) : null;
  const sectionTrimWidth = trims_width ? (
    <div className={css.sectionFeatures}>
      <h2 className={css.detailsTitle}>
        <FormattedMessage id="ListingPage.trimWidth" />
      </h2>
      <div className={css.detailsContent}>{trims_width}mm</div>
    </div>
  ) : null;
  const sectionTrimLength = trims_length ? (
    <div className={css.sectionFeatures}>
      <h2 className={css.detailsTitle}>
        <FormattedMessage id="ListingPage.trimLength" />
      </h2>
      <div className={css.detailsContent}>{trims_length}meter</div>
    </div>
  ) : null;

  return (
    <>
      {/* <div className={css.lengthContainer}> */}
      {sectionfabricsWidth}
      {sectionSwatchLength}
      {/* </div>
      <div className={css.lengthContainer}> */}
      {sectionTrimWidth}
      {sectionTrimLength}
      {/* </div> */}
    </>
  );
};

export default SectionSizeMaybe;
