import React from 'react';
import config from '../../config';
import ListingImageGallery from './ListingImageGallery/ListingImageGallery';

import css from './ListingPage.module.css';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const SectionGallery = props => {
  const { listing } = props;
  const images = listing.images;
  const history = useHistory();
  const title = listing.attributes.title || '';
  const { publicData } = listing?.attributes || {};
  const { category, subcategory, subSubCategory } = publicData || {};
  const intl = useIntl();
  const categoryLabel = intl
    .formatMessage({
      id: config.custom.categories?.find(cat => cat.key === category)?.label ?? '',
    })
    ?.toLowerCase();
  const subCategoryLabel = intl
    .formatMessage({
      id:
        config.custom[`${category}SubCategory`]?.find(cat => cat.key === subcategory)?.label ?? '',
    })
    ?.toLowerCase();
  const subSubCategoryLabel = intl
    .formatMessage({
      id:
        config.custom[`${subcategory}SubSubCategory`]?.find(cat => cat.key === subSubCategory)
          ?.label ?? '',
    })
    ?.toLowerCase();
  const { variantPrefix } = config.listing;
  const imageVariants = ['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge'];
  const thumbnailVariants = [variantPrefix, `${variantPrefix}-2x`, `${variantPrefix}-4x`];
  return (
    <>
      <ListingImageGallery
        rootClassName={css.productGallery}
        images={images}
        imageVariants={imageVariants}
        thumbnailVariants={thumbnailVariants}
        alt={title}
        listing={listing}
      />
      <div className={css.breadCrumbs}>
        {category ? (
          <span
            className={css.breadCrumb}
            onClick={() => {
              history.push(`/s?pub_category=${category}`);
            }}
          >
            <span className={css.breadCrumbLink}>{categoryLabel}</span>
          </span>
        ) : null}
        {subcategory ? (
          <span
            className={css.breadCrumb}
            onClick={() => {
              history.push(`/s?pub_category=${category}&pub_subcategory=${subcategory}`);
            }}
          >
            /<span className={css.breadCrumbLink}>{subCategoryLabel}</span>
          </span>
        ) : null}
        {subSubCategory ? (
          <span
            className={css.breadCrumb}
            onClick={() => {
              history.push(
                `/s?pub_category=${category}&pub_subcategory=${subcategory}&pub_subSubCategory=${subSubCategory}`
              );
            }}
          >
            /<span className={css.breadCrumbLink}>{subSubCategoryLabel}</span>
          </span>
        ) : null}
      </div>
    </>
  );
};

export default SectionGallery;
