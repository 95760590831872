import React from 'react';
import config from '../../config';
import css from './ListingPage.module.css';
import { FormattedMessage } from '../../util/reactIntl';
import { useIntl } from 'react-intl';
const SectionColors = props => {
  const { publicData = {} } = props;
  const { color } = publicData;

  if (!color) {
    return null;
  }
  const intl = useIntl();
  const colorOptions = color?.map(item =>
    config.custom.colorOptions.find(colorItem => colorItem.key === item)
  );

  return color?.length ? (
    <div className={css.sectionDetails}>
      <h2 className={css.detailsTitle}>
        <FormattedMessage id="ListingPage.color" />
      </h2>
      <div className={css.colorContainer}>
        {colorOptions?.map((item, index) => (
          <div className={css.color} key={index}>
            <p
              className={css.colorIcon}
              style={
                item?.color !== 'multi'
                  ? { backgroundColor: `${item.color}`, border: `1px solid ${item.border ?? null}` }
                  : {
                      backgroundImage:
                        'linear-gradient(to right, red,orange,yellow,green,blue,indigo,violet)',
                    }
              }
            ></p>
            <p>{intl.formatMessage({ id: item.label })}</p>
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

export default SectionColors;
